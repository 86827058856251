/* Review Button Colors */
$white-color: #fff;
$black-color: #000;
$red-color: #ef5350;
$blue-color: #42a5f5;
$green-color: #66bb6a;
$brown-color: #8d6e63;
$gray-color: #707070;
$doveGray-color: #707070;
$charcoal-color: #788195;
$scrollbar-dark: #4f586e;
$scrollbar-bg-color: #333947;

/* New Colors set*/
$search-input-btn-bg-color: #fafafa;
$search-input-bg-color: #fafbfd;
$black-bg-color: #212734;
$grey-avatar-color: #d2d2d2;
$grey-avatar-text-color: #c1c1c1;
$grey-canvas-header-color: #2a2f3c;
$grey-button-color: #404552;
$grey-button-hover-color: #4a4f5e;
$grey-li-text-color: #bebebe;
$canvas-header-border-color: #424858;
$canvas-card-blue-color: #42a5f5;
$canvas-gallery-grey-color: #b3b3b3;
$side-panel-blue-color: #2b8ede;
$side-panel-grey-color: #c1c7d5;
$side-panel-background-color: #2a2f3b;
$side-panel-header-color: #78909c;
$dropdown-option-color: #9ea4b3;
$action-upload-btn-color: #c1c7d4;
$design-tool-icon-main-color: #6a7287;
$ortho-design-tool-icon-main-color: #ffffff;
$design-tool-slider-handle-color: #3c3f45;
$design-tool-slider-rail-color: #686868;
$side-panel-comment-button-dot-color: #db2d2a;
$reviewer-action-container-bg-color: #1b202b;
$reviewer-action-button-bg-color: #3e4961;
$reviewer-dismiss-message-color: #ff8583;
$fmx-placeholder-gradient-color: #272d39;
$fmx-placeholder-border-color: #545c6d;
$fmx-placeholder-image-color: #4b4b4b;
$fmx-placeholder-image-green-color: #b8e986;
$fmx-image-hover-border-color: #9cd3ff;
$column-control-icon-color: #78909c;
$ortho-side-panel-heading-highlights: #384464;

$ojrr-deny-color: #ff8684;
$ojrr-clinical-review-color: #b498ff;
$ojrr-accept-color: #a8ecab;
$ojrr-partial-deny-color: #f1deae;
$ojrr-pend-color: #e0af9e;
$ojrr-review-color: #80c6ff;
$ojrr-RFI-color: #8fd8cb;
$ojrr-partial-accept-color: #f1deae;
$ojrr-route-color: #e0af9e;
$ojrr-release-color: #8fd8cb;
$ojrr-upload-color: #80c6ff;
